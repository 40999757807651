import React from 'react'

function Getstarted() {
  return (
    <>
      <div class="py-12">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto max-w-2xl lg:text-center">
            <p class="text-3xl font-bold tracking-tight text-red-600 sm:text-4xl">What is ProjectSync</p>
            <p class="mt-4 text-lg leading-8 text-white">Welcome to <span class="font-bold text-blue-600">ProjectSync</span>, the hub of innovation and collaboration!</p>
            <p class="mt-3 text-lg leading-8 text-white">At <span class="font-bold text-red-600">ProjectSync</span>, we're dedicated to fostering knowledge sharing, collaboration, and career opportunities for students, researchers, and innovators from diverse backgrounds. Our platform is designed to empower the next generation of creative minds.</p>
          </div>
          <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              <div class="relative pl-16">
                <dt class="text-base font-semibold leading-7 text-gray-900">
                  <div class="absolute shadow-[14px_22px_70px__40px_rgba(8,_112,_184,_0.3)] left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                    </svg>
                  </div>
                  <span class="text-white">Project Repository</span>
                </dt>
                <dd class="mt-2 text-base leading-7 text-gray-600">Our comprehensive project repository is the heart of our platform. Users can easily upload their completed project works, including essential details such as titles, abstracts, objectives, methodologies, findings, and conclusions.</dd>
              </div>
              <div class="relative pl-16">
                <dt class="text-base font-semibold leading-7 text-gray-900">
                  <div class="absolute shadow-[0px_22px_70px__40px_rgba(178,_222,_39,_0.25)] left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                    <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                    </svg>
                  </div>
                  <span class="text-white">Knowledge Sharing and Learning</span>
                </dt>
                <dd class="mt-2 text-base leading-7 text-gray-600"> Access a diverse collection of projects contributed by individuals from various fields. Explore projects aligned with your interests, learn from peer experiences, and discover innovative approaches.</dd>
              </div>
              <div class="relative pl-16">
                <dt class="text-base font-semibold leading-7 text-gray-900">
                  <div class="absolute shadow-[14px_22px_70px__40px_rgba(8,_112,_184,_0.3)] left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-cyan-600">
                    <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                  </div>
                  <span class="text-white">Plagiarism Detection</span>
                </dt>
                <dd class="mt-2 text-base leading-7 text-gray-600"> We uphold integrity by incorporating an advanced plagiarism detection system. Every project undergoes rigorous scrutiny to ensure its originality before being accepted into the repository.</dd>
              </div>
              <div class="relative pl-16">
                <dt class="text-base font-semibold leading-7 text-gray-900">
                  <div class="absolute shadow-[14px_22px_70px__40px_rgba(219,_47,_220,_0.25)] left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-purple-600">
                    <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
                    </svg>
                  </div>
                  <span class="text-white">Cross-Functional Collaboration</span>
                </dt>
                <dd class="mt-2 text-base leading-7 text-gray-600">Foster collaboration between members from different backgrounds. This encourages interdisciplinary projects, allowing individuals to address complex challenges from multiple angles.</dd>
              </div>
              <div class="relative pl-16">
                <dt class="text-base font-semibold leading-7 text-gray-900">
                  <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
                    </svg>
                  </div>
                  <span class="text-white">Industry Interaction</span>
                </dt>
                <dd class="mt-2 text-base leading-7 text-gray-600"> Showcase your high-quality and innovative projects to potential partners and employers. This exposure can lead to valuable partnerships, collaborations, and opportunities.</dd>
              </div>
              <div class="relative pl-16">
                <dt class="text-base font-semibold leading-7 text-gray-900">
                  <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
                    </svg>
                  </div>
                  <span class="text-white">User-Friendly Interface</span>
                </dt>
                <dd class="mt-2 text-base leading-7 text-gray-600">Our platform boasts a user-friendly interface, making it simple for users to upload and browse projects. The robust search functionality enables users to find projects based on keywords, categories, or institutions.</dd>
              </div>
              <div class="relative pl-16">
                <dt class="text-base font-semibold leading-7 text-gray-900">
                  <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
                    </svg>
                  </div>
                  <span class="text-white">Data Privacy and Security</span>
                </dt>
                <dd class="mt-2 text-base leading-7 text-gray-600">We prioritize data privacy and security, safeguarding your intellectual property and personal information with stringent measures to ensure confidentiality and trust.</dd>
              </div>
              <br/>
            </dl>
            <dd class="mt-3 text-lg leading-8 text-white">Not only can members share their creative projects within our community, but they can also sell their innovative solutions to various industries. We provide a gateway for individuals to showcase their talents and connect with potential buyers in the corporate world.

                At ProjectSync, we believe in the power of knowledge, collaboration, and innovation. Join our community today and embark on a journey of creativity, growth, and boundless opportunities. Explore, learn, and succeed with us!</dd>
          </div>
        </div>
      </div>

    </>
  )
}

export default Getstarted